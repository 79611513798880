
<template>
  <section ref="childPage">
    <a-form class="form-container m-t-18" :form="form">
      <section class="form-item">
        <p class="title">单位信息</p>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="单位名称">
              <a-select
                showSearch
                :filterOption="filterOption"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                option-filter-prop="children"
                placeholder="请选择"
                v-decorator="[
                  'companyId',
                  { rules: [{ required: false, message: '请选择单位' }] },
                ]"
                @change="(e) => changeCompany(e)"
              >
                <a-select-option
                  v-for="item in companyMap"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.companyName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="统一信用代码">
              <a-input
                disabled
                placeholder="请选择单位"
                v-decorator="[
                  'socialCreditCode',
                  {
                    rules: [{ required: false, message: '请输入统一信用代码' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="单位类型">
              <a-select
                disabled
                placeholder="请选择单位"
                v-decorator="[
                  'companyTypeId',
                  { rules: [{ required: false, message: '请输入单位类型' }] },
                ]"
              >
                <a-select-option
                  v-for="item in companyTypeMap"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.companyTypeName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 人员信息 -->
      <section class="form-item m-t-10">
        <p class="title">
          人员信息
          <span style="color: #3399ff; font-size: 14px; margin-left: 40px"
            >身份证识别</span
          >
          <span
            style="color: red; font-size: 14px; margin-left: 40px"
            v-if="isDis == true"
            >黑名单人员禁止入场</span
          >
        </p>
        <a-row>
          <a-col :span="8">
            <a-form-item label="人脸照片">
              <!-- <div
                class="face-photo-wrap"
                v-decorator="[
                  'avatar',
                  { rules: [{ required: false, message: '请上传人脸照片' }] },
                ]"
              >
                <a class="icon-wrap" v-if="avatar">
                  <img style="width: 100%" :src="avatar" alt="" />
                </a>
                <a class="icon-wrap" v-else @click="showDeviceModal">
                  <a-icon
                    style="font-size: 20px"
                    :type="loadTakeImageStatus ? 'loading' : 'plus'"
                  />
                </a>

                <a class="text" @click="showDeviceModal">{{
                  loadTakeImageStatus ? "上传中···" : "人脸拍照"
                }}</a>
              </div>-->

              <div style="display: flex; align-items: center">
                <upload
                  style="display: inline-block; width: 100px"
                  v-decorator="[
                    'avatar',
                    { rules: [{ required: false, message: '请上传照片' }] },
                  ]"
                  :node="{ type: 3, text: '上传' }"
                  :single="true"
                  :accept="['.png', '.jpg']"
                  :uploadUp="'uploadUpMore'"
                  @func="uploadFile"
                  :url="avatar"
                ></upload>

                <!-- <upload
                  style="display: inline-block; width: 100px"
                  v-decorator="[
                    'avatar',
                    { rules: [{ required: false, message: '请上传照片' }] },
                  ]"
                  :node="{ type: 1, text: '人脸拍照' }"
                  :single="true"
                  :accept="['.png', '.jpg']"
                  :uploadUp="'uploadUpMore'"
                  @func="uploadFile"
                  :url="avatar"
                >
                </upload>-->
                <div
                  style="margin-left: 20px; color: #3399ff"
                  @click="renlianSbie"
                >
                  人脸拍照
                </div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证照片">
              <div style="display: flex; align-items: center">
                <div>
                  <img
                    style="width: 80px; height: 80px"
                    :src="sfzUrl"
                    v-if="sfzUrl"
                    alt
                  />
                  <div v-else>无图像</div>
                </div>
                <div style="margin-left: 40px; color: #3399ff">
                  <!-- <a class="text">照片识别</a> -->
                  <div @click="zpSbie">照片识别</div>
                  <!-- <upload
                    style="display: inline-block; width: 100px"
                    v-decorator="[
                      'idCardPhoto',
                      { rules: [{ required: false, message: '请上传照片' }] },
                    ]"
                    :node="{ type: 1, text: '照片识别' }"
                    :single="true"
                    :accept="['.png', '.jpg']"
                    :uploadUp="'uploadUpMore'"
                    @func="uploadFile2"
                    :url="avatar"
                  >
                  </upload>-->
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="人员姓名">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'personnelName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入姓名',
                        whitespace: true,
                      },
                      { max: 5, message: '最大输入5个汉字' },
                      { validator: validator.chineseReg },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证号">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'idCard',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入身份证号',
                        whitespace: true,
                      },
                      { validator: validator.idCardReg },
                    ],
                  },
                ]"
                @blur="checkIsblick"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="签发机关">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'issuingAuthority',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入签发机关',
                        whitespace: true,
                      },
                      { max: 30, message: '最大输入30个字符' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="有效日期">
              <div style="display: flex; align-items: center">
                <a-range-picker
                  v-show="!setValueStatus"
                  v-decorator="[
                    'endValid',
                    {
                      rules: [
                        {
                          required: !setValueStatus,
                          message: '请选择有效日期',
                        },
                      ],
                    },
                  ]"
                />
                <a-date-picker
                  v-show="setValueStatus"
                  v-decorator="[
                    'endValid2',
                    {
                      rules: [
                        {
                          required: setValueStatus,
                          message: '请选择有效日期',
                        },
                      ],
                    },
                  ]"
                />
                <a-checkbox
                  v-decorator="[
                    'idCardValidityStatus',
                    {
                      rules: [
                        { required: setValueStatus, message: '请选择有效日期' },
                      ],
                    },
                  ]"
                  :checked="setValueStatus"
                  @change="changqiCg"
                  style="min-width: 60px; margin-left: 6px"
                  >长期</a-checkbox
                >
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="民族">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                showSearch
                option-filter-prop="children"
                placeholder="请选择"
                v-decorator="[
                  'nation',
                  { rules: [{ required: true, message: '请选择民族' }] },
                ]"
              >
                <a-select-option
                  v-for="(item, ind) in nationalList"
                  :key="ind"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="性别">
              <a-radio-group
                v-decorator="[
                  'sex',
                  { rules: [{ required: true, message: '请选择性别' }] },
                ]"
              >
                <a-radio :value="1">男</a-radio>
                <a-radio :value="2">女</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="户籍地址">
              <a-cascader
                :options="ssqList"
                placeholder="请选择"
                @change="onChange"
                :fieldNames="{
                  label: 'areaName',
                  value: 'id',
                  children: 'children',
                }"
                v-decorator="[
                  'personnelCityId',
                  { rules: [{ required: false, message: '请选择户籍地址' }] },
                ]"
              />
              <!-- <a-input
                placeholder="请选择"
                v-decorator="[
                  'residenceAddress',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择户籍地址',
                        whitespace: true,
                      },
                      { max: 30, message: '最大输入30个字符' },
                    ],
                  },
                ]"
              />-->
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="详细地址">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'residenceAddress',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入详细地址',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="政治面貌">
              <a-select
                placeholder="请选择"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-decorator="[
                  'partyMember',
                  { rules: [{ required: false, message: '请选择政治面貌' }] },
                ]"
              >
                <a-select-option :value="1">党员</a-select-option>
                <a-select-option :value="0">非党员</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系电话">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'phoneNumber',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入联系电话',
                        whitespace: true,
                      },
                      { validator: validator.phoneReg },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="婚姻状态">
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'maritalStatus',
                  { rules: [{ required: false, message: '请选择婚姻状态' }] },
                ]"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option :value="0">未婚</a-select-option>
                <a-select-option :value="1">已婚</a-select-option>
                <a-select-option :value="2">离异</a-select-option>
                <a-select-option :value="3">丧偶</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="教育水平">
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'educationLevel',
                  { rules: [{ required: false, message: '请选择教育水平' }] },
                ]"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option :value="1">初中</a-select-option>
                <a-select-option :value="2">高中</a-select-option>
                <a-select-option :value="3">中专</a-select-option>
                <a-select-option :value="4">大专</a-select-option>
                <a-select-option :value="5">本科</a-select-option>
                <a-select-option :value="6">硕士</a-select-option>
                <a-select-option :value="7">博士</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="家庭地址">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'familyAddress',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请输入家庭地址',
                        whitespace: true,
                      },
                      { max: 30, message: '最大输入30个字符' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户行">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'payBankname',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请输入开户行',
                        whitespace: true,
                      },
                      { max: 25, message: '最大输入25个字符' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="银行卡号">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'payCardnum',
                  { rules: [{ required: false, message: '请输入银行卡号' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="所属支行">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'payBranch',
                  {
                    rules: [
                      { required: false, message: '请输入所属支行' },
                      { max: 25, message: '最大输入25个字符' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="标记">
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'liveBuiltBureau',
                  { rules: [{ required: false, message: '请选择是否标记' }] },
                ]"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              >
                <a-select-option :value="0">否</a-select-option>
                <a-select-option :value="1">是</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="personnelType == 2">
            <a-form-item label="人员类型">
              <a-select
                placeholder="请选择"
                v-decorator="[
                  'personnelType',
                  { rules: [{ required: false, message: '请选择人员类型' }] },
                ]"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                @change="(e) => changePersonType(e)"
              >
                <a-select-option :value="2">劳务管理人员</a-select-option>
                <a-select-option :value="3">务工人员</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="备注说明">
              <a-textarea
                placeholder="请输入"
                :rows="4"
                v-decorator="[
                  'remarks',
                  {
                    rules: [{ required: false, message: '请输入备注说明' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 紧急联系人 -->
      <section class="form-item m-t-10">
        <p class="title">紧急联系人</p>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="紧急联系人">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'emergencyContact',
                  { rules: [{ required: false, message: '请输入紧急联系人' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="紧急联系人电话">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'contactNumber',
                  {
                    rules: [
                      { required: false, message: '请输入紧急联系人电话' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 职务情况 -->
      <section
        class="form-item m-t-10"
        v-if="personnelType == 1 || labourType == 2"
      >
        <p class="title">职务情况</p>
        <a-row>
          <a-col :span="8">
            <a-form-item label="职务名称">
              <a-select
                showSearch
                option-filter-prop="children"
                placeholder="请选择"
                v-decorator="[
                  'positionWorkId',
                  { rules: [{ required: false, message: '请选择职务' }] },
                ]"
              >
                <a-select-option
                  v-for="(item, key) in jobsMap"
                  :key="key"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 班组信息 -->
      <section
        class="form-item m-t-10"
        v-if="personnelType == 2 && labourType == 3"
      >
        <p class="title">班组信息</p>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="班组名称">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                showSearch
                option-filter-prop="children"
                placeholder="请选择"
                v-decorator="[
                  'teamId',
                  { rules: [{ required: true, message: '请选择班组' }] },
                ]"
              >
                <a-select-option
                  v-for="(item, key) in teamMap"
                  :key="key"
                  :value="item.id"
                  >{{ item.teamName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="是否组长">
              <a-radio-group
                v-decorator="[
                  'isLeader',
                  { rules: [{ required: true, message: '请选择是否组长' }] },
                ]"
              >
                <a-radio :value="1">是</a-radio>
                <a-radio :value="2">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 工种信息 -->
      <section
        class="form-item m-t-10"
        v-if="personnelType == 2 && labourType == 3"
      >
        <p class="title">工种信息</p>
        <a-row>
          <a-col :span="8">
            <a-form-item label="工种名称">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                showSearch
                option-filter-prop="children"
                placeholder="请选择"
                v-decorator="[
                  'positionWorkId',
                  { rules: [{ required: true, message: '请选择工种名称' }] },
                ]"
              >
                <a-select-option
                  v-for="(item, key) in jobsMap"
                  :key="key"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 出入场信息 -->
      <section class="form-item m-t-10">
        <p class="title">出入场信息</p>
        <a-row>
          <a-col :span="8">
            <a-form-item label="登记时间">
              <a-date-picker
                ref="inDate"
                placeholder="请选择"
                v-decorator="[
                  'inDate',
                  { rules: [{ required: false, message: '请选择登记时间' }] },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </section>
      <!-- 资格证书 -->
      <section class="form-item m-t-10" v-if="personnelType == 1">
        <p class="title">
          资格证书
          <a class="action-btn" @click="showCertificateModal(null)">
            <a-icon type="plus" />
          </a>
        </p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="certificate.columns"
            :data-source="certificate.data"
            :pagination="false"
          >
            <a-row v-if="list.certUrl" slot="certUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
            <a slot="action" slot-scope="text, list">
              <a class="m-r-10" @click="showCertificateModal(list)">编辑</a>
              <a-popconfirm title="确定删除?" @confirm="deleteCertificate(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>
      <!-- 特种作业证 -->
      <section
        class="form-item m-t-10"
        v-if="personnelType == 2 && labourType == 3"
      >
        <p class="title">
          特种作业证
          <a
            v-if="specialOperation.data.length < 1"
            class="action-btn"
            @click="showSpecialOperationModal(null)"
          >
            <a-icon type="plus" />
          </a>
        </p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="specialOperation.columns"
            :data-source="specialOperation.data"
            :pagination="false"
          >
            <a-row v-if="list.certUrl" slot="certUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
            <a slot="action" slot-scope="text, list">
              <a class="m-r-10" @click="showSpecialOperationModal(list)"
                >编辑</a
              >
              <a-popconfirm
                title="确定删除?"
                @confirm="deleteSpecialOperation(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>

      <!-- 证照管理 -->
      <section class="form-item m-t-10">
        <p class="title">
          证照管理
          <!-- <a class="action-btn"><a-icon type="plus" /></a> -->
        </p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="licence.columns"
            :data-source="licence.data"
            :pagination="false"
          >
            <a-row v-if="list.fileUrl" slot="fileUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
            <a slot="action" slot-scope="text, list">
              <span
                @click="fileSbie('sfzUrl', list.fileMaterialName)"
                style="font-size: 14px; margin-right: 10px"
                >拍照</span
              >
              <span
                @click="licence.name = list.fileMaterialName"
                v-if="!list.fileUrl"
              >
                <upload
                  @func="getLicenceFile"
                  style="display: inline-block"
                  class="m-r-10"
                  :node="{ type: 1, text: '上传' }"
                  :accept="['.png', '.jpg', '.pdf']"
                />
              </span>
              <a-popconfirm
                v-else
                title="确定删除?"
                @confirm="deleteLicenceFile(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>

      <!-- 人员合同 -->
      <section class="form-item m-t-10">
        <p class="title">人员合同</p>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="合同编号">
              <a-input
                placeholder="请输入"
                v-decorator="[
                  'contractNo',
                  { rules: [{ required: false, message: '请输入合同编号' }] },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="合同开始时间">
              <a-date-picker
                v-decorator="[
                  'handleDate',
                  {
                    rules: [{ required: false, message: '请选择合同开始时间' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="合同结束时间">
              <a-date-picker
                v-decorator="[
                  'endDate',
                  {
                    rules: [{ required: false, message: '请选择合同结束时间' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="contract.columns"
            :data-source="contract.data"
            :pagination="false"
          >
            <a-row v-if="list.fileUrl" slot="fileUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
            <a slot="action" slot-scope="text, list">
              <span
                @click="fileSbie('hetUrl')"
                style="font-size: 14px; margin-right: 10px"
                >拍照</span
              >
              <upload
                @func="getContractFile"
                v-if="!list.fileUrl"
                style="display: inline-block"
                class="m-r-10"
                :node="{ type: 1, text: '上传' }"
                :accept="['.png', '.jpg', '.pdf']"
              />
              <a-popconfirm
                v-else
                title="确定删除?"
                @confirm="deleteContractFile"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>

      <!-- 体检信息 -->
      <section class="form-item m-t-10">
        <p class="title">
          体检信息
          <a class="action-btn" @click="showTestModal(null)">
            <a-icon type="plus" />
          </a>
        </p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="test.columns"
            :data-source="test.data"
            :pagination="false"
          >
            <a-row
              v-if="list.medicalUrl"
              slot="medicalUrl"
              slot-scope="text, list"
            >
              <preview-image :data="text" :size="44" />
            </a-row>
            <a slot="action" slot-scope="text, list">
              <a class="m-r-10" @click="showTestModal(list)">编辑</a>
              <a-popconfirm title="确定删除?" @confirm="deleteTest(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>

      <!-- 从业信息 -->
      <section class="form-item m-t-10">
        <p class="title">
          从业信息
          <a @click="showWorkExperiencesModal(null)" class="action-btn">
            <a-icon type="plus" />
          </a>
        </p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="workExperiences.columns"
            :data-source="workExperiences.data"
            :pagination="false"
          >
            <a slot="action" slot-scope="text, list">
              <a class="m-r-10" @click="showWorkExperiencesModal(list)">编辑</a>
              <a-popconfirm
                title="确定删除?"
                @confirm="deleteWorkExperiences(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>

      <!-- 资料文件 -->
      <section class="form-item m-t-10">
        <p class="title">
          资料文件
          <a class="action-btn" @click="showFileDataModal(null)">
            <a-icon type="plus" />
          </a>
        </p>
        <section class="list-table-container">
          <a-table
            :bordered="false"
            size="small"
            class="m-t-18"
            :columns="fileData.columns"
            :data-source="fileData.data"
            :pagination="false"
          >
            <a-row v-if="list.fileUrl" slot="fileUrl" slot-scope="text, list">
              <preview-image :data="text" :size="44" />
            </a-row>
            <a slot="action" slot-scope="text, list">
              <a class="m-r-10" @click="showFileDataModal(list)">编辑</a>
              <a-popconfirm title="确定删除?" @confirm="deleteFileData(list)"
                >删除</a-popconfirm
              >
            </a>
          </a-table>
        </section>
      </section>

      <section class="form-item m-t-10">
        <div class="form-btn-group" style="text-align: center; padding: 18px 0">
          <a-button @click="submit" :disabled="isDis">
            <a-icon type="save" />保存
          </a-button>
          <a-button @click="$router.back()">取消</a-button>
        </div>
      </section>
      <select id="curDev" style="width: 90px; opacity: 0" name="selDev">
        <option value="0">E1500 Pro</option>
        <option value="1">USB CAM2</option>
      </select>
    </a-form>
    <!-- modal -->
    <a-modal
      centered
      width="660px"
      class="modal-container"
      :title="certificate.title"
      v-model="certificate.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-form :form="certificateForm">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="专业名称"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'professionName',
              { rules: [{ required: false, message: '专业名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>

        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="证书名称"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'jobTitle',
              { rules: [{ required: true, message: '证书名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="证书编号"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'certNumber',
              { rules: [{ required: false, message: '证书编号不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="到期时间"
        >
          <a-date-picker
            placeholder="请选择"
            v-decorator="[
              'certEndTime',
              { rules: [{ required: true, message: '到期时间不能为空' }] },
            ]"
            class="form-input"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="附件"
        >
          <a-row type="flex">
            <a-button class="gpBtn gpBtnCard" @click="fileSbie('certUrl')"
              >拍照</a-button
            >
            <upload
              @func="getCertificateFile"
              style="display: inline-block; width: 100px"
              v-decorator="[
                'certUrl',
                { rules: [{ required: false, message: '请上传附件' }] },
              ]"
              :node="{ type: 3, text: '上传', icon: 'plus' }"
              :accept="['.png', '.jpg', '.pdf']"
            />
          </a-row>
          <a-row type="flex">
            <a-row class="upload-preview-wrap">
              <a v-if="certificate.files.url" class="file-item m-r-10">
                <preview-image :data="certificate.files.url" :size="100" />
              </a>
            </a-row>
          </a-row>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="saveCertificate">
            <a-icon type="save" />保存
          </a-button>
          <a-button class="btn-cancel" @click="certificate.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
    <!-- 特种作业 -->
    <a-modal
      centered
      width="660px"
      class="modal-container"
      :title="specialOperation.title"
      v-model="specialOperation.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-form :form="specialOperationForm">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="证件号"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'certNumber',
              { rules: [{ required: true, message: '证件号不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="初领时间"
        >
          <a-date-picker
            placeholder="请选择"
            v-decorator="[
              'certFirstTime',
              { rules: [{ required: true, message: '初领时间不能为空' }] },
            ]"
            class="form-input"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="有效日期"
        >
          <a-range-picker
            v-decorator="[
              'certEndTime',
              { rules: [{ required: true, message: '有效日期不能为空' }] },
            ]"
            class="form-input"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="附件"
        >
          <a-row type="flex">
            <a-row class="upload-preview-wrap">
              <a v-if="specialOperation.files.url" class="file-item m-r-10">
                <preview-image :data="specialOperation.files.url" :size="100" />
              </a>
            </a-row>
            <upload
              @func="getSpecialOperationFile"
              style="display: inline-block; width: 100px"
              v-decorator="[
                'certUrl',
                { rules: [{ required: true, message: '请上传附件' }] },
              ]"
              :node="{ type: 3, text: '上传', icon: 'plus' }"
              :accept="['.png', '.jpg']"
            />
          </a-row>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="saveSpecialOperation">
            <a-icon type="save" />保存
          </a-button>
          <a-button class="btn-cancel" @click="specialOperation.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
    <!--体检  -->
    <a-modal
      centered
      width="660px"
      class="modal-container"
      :title="test.title"
      v-model="test.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-form :form="testForm">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="体检时间"
        >
          <a-date-picker
            placeholder="请选择"
            v-decorator="[
              'medicalDate',
              { rules: [{ required: true, message: '体检时间不能为空' }] },
            ]"
            class="form-input"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="体检结果"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'medicalResult',
              { rules: [{ required: true, message: '体检结果不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="附件"
        >
          <a-row type="flex">
            <a-button class="gpBtn gpBtnCard" @click="fileSbie('medicalUrl')"
              >拍照</a-button
            >
            <upload
              @func="getTestFile"
              style="display: inline-block; width: 100px"
              v-decorator="[
                'medicalUrl',
                { rules: [{ required: false, message: '请上传附件' }] },
              ]"
              :node="{ type: 3, text: '上传', icon: 'plus' }"
              :accept="['.png', '.jpg']"
            />
          </a-row>
          <a-row type="flex">
            <a-row class="upload-preview-wrap">
              <a v-if="test.files.url" class="file-item m-r-10">
                <preview-image :data="test.files.url" :size="100" />
              </a>
            </a-row>
          </a-row>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="saveTest">
            <a-icon type="save" />保存
          </a-button>
          <a-button class="btn-cancel" @click="test.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
    <!-- 从业经历 -->
    <a-modal
      centered
      width="660px"
      class="modal-container"
      :title="workExperiences.title"
      v-model="workExperiences.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-form :form="workExperiencesForm">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="参与项目"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'siteName',
              { rules: [{ required: false, message: '参与项目不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="原工作单位名称"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'workUnitName',
              {
                rules: [{ required: false, message: '原工作单位名称不能为空' }],
              },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="进场时间"
        >
          <a-date-picker
            placeholder="请选择"
            v-decorator="[
              'startDate',
              { rules: [{ required: true, message: '进场时间不能为空' }] },
            ]"
            class="form-input"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="退场时间"
        >
          <a-date-picker
            placeholder="请选择"
            v-decorator="[
              'endDate',
              { rules: [{ required: true, message: '退场时间不能为空' }] },
            ]"
            class="form-input"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="职务名称"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'workType',
              { rules: [{ required: true, message: '职务名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="证明人"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'workKind',
              { rules: [{ required: true, message: '证明人不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="saveWorkExperiences">
            <a-icon type="save" />保存
          </a-button>
          <a-button class="btn-cancel" @click="workExperiences.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      centered
      width="920px"
      class="modal-container"
      :title="deviceModal.title"
      v-model="deviceModal.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-row type="flex">
        <a-col style="width: 600px">
          <section class="search-container">
            <a-form layout="inline" class="m-t-14">
              <a-form-item label="设备类型">
                <a-select
                  class="search-select"
                  placeholder="全部"
                  @change="(e) => queryGateMachineList(e)"
                  v-model="deviceModal.type"
                >
                  <a-select-option value>全部</a-select-option>
                  <a-select-option value="1">门禁设备</a-select-option>
                  <a-select-option value="2">考勤设备</a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </section>
          <section class="list-table-container">
            <a-table
              :bordered="false"
              size="small"
              class="m-t-18"
              :columns="deviceModal.columns"
              :data-source="deviceModal.data"
              :pagination="{ hideOnSinglePage: true, pageSize: 8 }"
            >
              <span
                slot="deviceState"
                :style="{ color: text === 1 ? 'green' : 'red' }"
                slot-scope="text"
                >{{ text === 1 ? "在线" : "离线" }}</span
              >
              <a slot="action" slot-scope="text, list">
                <a v-if="list.deviceState == 1">
                  <span v-if="loadTakeImageStatus">
                    <span v-if="list.deviceCode == currentDeviceCode">
                      <a-icon type="loading" class="m-r-8" />
                    </span>
                    <span
                      v-else
                      style="color: rgba(255, 255, 255, 0.6); cursor: default"
                      >拍照</span
                    >
                  </span>
                  <a v-else @click="queryPhotograph(list.deviceCode)">拍照</a>

                  <!-- <span v-if="loadTakeImageStatus"><a-icon type="loading" class="m-r-8" /></span>
                  <a v-else @click="queryPhotograph(list.deviceCode)">拍照</a>-->
                </a>
                <span
                  v-else
                  style="color: rgba(255, 255, 255, 0.6); cursor: default"
                  >拍照</span
                >
              </a>
            </a-table>
          </section>
        </a-col>
        <a-col style="width: calc(100% - 600px)">
          <a-row type="flex" style="height: 100%" justify="center">
            <a-row class="take-face-image">
              <img
                v-if="personnelFaceImage"
                :src="picServer + personnelFaceImage"
              />
            </a-row>
          </a-row>
        </a-col>
      </a-row>
      <a-row class="form-btn-group m-t-18" style="text-align: right">
        <a-button class="btn-save" @click="saveAvatar">
          <a-icon type="save" />保存
        </a-button>
        <a-button class="btn-cancel" @click="deviceModal.visible = false"
          >取消</a-button
        >
      </a-row>
    </a-modal>
    <!-- 身份证照片 -->
    <a-modal
      centered
      width="500px"
      class="modal-container"
      :title="idcardModal.title"
      v-model="idcardModal.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-row type="flex">
        <a-col style="width: 400px">
          <a-row type="flex" style="height: 100%" justify="center">
            <!-- v-if="personnelFaceImage"
            :src="picServer + personnelFaceImage"-->
            <a-row
              style="
                width: 300px;
                height: 210px;
                margin-top: 30px;
                padding: 4px;
                border-radius: 4px;
                border: 1px solid rgba(255, 255, 255, 0.4);
              "
            >
              <img id="myCanvas" style="width: 100%; height: 100%" />
            </a-row>
          </a-row>
        </a-col>
      </a-row>
      <a-row class="form-btn-group m-t-18" style="text-align: center">
        <a-button @click="paizhao" class="btn-save">{{ textP }}</a-button>
        <a-button class="btn-save" @click="baocun">
          <a-icon type="save" />保存
        </a-button>
        <a-button class="btn-cancel" @click="quxiao">取消</a-button>
      </a-row>
    </a-modal>

    <!-- 人脸照片 -->
    <a-modal
      centered
      width="500px"
      class="modal-container"
      :title="faceModal.title"
      v-model="faceModal.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-row type="flex">
        <a-col style="width: 400px">
          <a-row type="flex" style="height: 100%" justify="center">
            <!-- v-if="personnelFaceImage"
            :src="picServer + personnelFaceImage"-->
            <a-row
              style="
                width: 300px;
                height: 210px;
                margin-top: 30px;
                padding: 4px;
                border-radius: 4px;
                border: 1px solid rgba(255, 255, 255, 0.4);
              "
            >
              <img id="myCanvas" style="width: 100%; height: 100%" />
            </a-row>
          </a-row>
        </a-col>
      </a-row>
      <a-row class="form-btn-group m-t-18" style="text-align: center">
        <a-button @click="paizhao2" class="btn-save">{{ textP2 }}</a-button>
        <a-button class="btn-save" @click="baocun2">
          <a-icon type="save" />保存
        </a-button>
        <a-button class="btn-cancel" @click="quxiao2">取消</a-button>
      </a-row>
    </a-modal>

    <!-- 文件照片 -->
    <div class="bankuai" v-if="fileModal.visible">
      <a-modal
        centered
        width="500px"
        class="modal-container quanzhong"
        :title="fileModal.title"
        v-model="fileModal.visible"
        :footer="false"
        :destroyOnClose="true"
      >
        <a-row type="flex">
          <a-col style="width: 400px">
            <a-row type="flex" style="height: 100%" justify="center">
              <!-- v-if="personnelFaceImage"
            :src="picServer + personnelFaceImage"-->
              <a-row
                style="
                  width: 300px;
                  height: 210px;
                  margin-top: 30px;
                  padding: 4px;
                  border-radius: 4px;
                  border: 1px solid rgba(255, 255, 255, 0.4);
                "
              >
                <img id="myCanvas" style="width: 100%; height: 100%" />
              </a-row>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="form-btn-group m-t-18" style="text-align: center">
          <a-button @click="paizhao3" class="btn-save">{{ textP3 }}</a-button>
          <a-button class="btn-save" @click="baocun3">
            <a-icon type="save" />保存
          </a-button>
          <a-button class="btn-cancel" @click="quxiao3">取消</a-button>
        </a-row>
      </a-modal>
    </div>

    <!--资料文件  -->
    <a-modal
      centered
      width="660px"
      class="modal-container"
      :title="fileData.title"
      v-model="fileData.visible"
      :footer="false"
      :destroyOnClose="true"
    >
      <a-form :form="fileDataForm">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="文件名称"
        >
          <a-input
            placeholder="请输入"
            v-decorator="[
              'fileName',
              { rules: [{ required: true, message: '文件名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="文件备注"
        >
          <a-textarea
            placeholder="请输入"
            :rows="4"
            v-decorator="[
              'remarks',
              {
                rules: [{ required: false, message: '请输入备注说明' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="附件"
        >
          <a-row type="flex">
            <a-button class="gpBtn gpBtnCard" @click="fileSbie('fileDataUrl')"
              >拍照</a-button
            >
            <upload
              @func="getFileDataFile"
              style="display: inline-block; width: 100px"
              v-decorator="[
                'fileUrl',
                { rules: [{ required: false, message: '请上传附件' }] },
              ]"
              :node="{ type: 3, text: '上传', icon: 'plus' }"
              :accept="['.png', '.jpg']"
            />
          </a-row>
          <a-row type="flex">
            <a-row class="upload-preview-wrap">
              <a v-if="fileData.files.url" class="file-item m-r-10">
                <preview-image :data="fileData.files.url" :size="100" />
              </a>
            </a-row>
          </a-row>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" @click="saveFileData">
            <a-icon type="save" />保存
          </a-button>
          <a-button class="btn-cancel" @click="fileData.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import main from "./main.js"; //页面逻辑

import validator from "../../../../until/validator";
import DataTransformUtils from "@/utils/DataTransformUtils";
import upload from "@/components/upload";
import moment from "moment";
import { nationalList } from "@/until/unit";
import previewImage from "@/components/previewImage";

const certificateColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "专业名称",
    dataIndex: "professionName",
    align: "center",
    key: "professionName",
  },
  {
    title: "证书名称",
    dataIndex: "jobTitle",
    align: "center",
    key: "jobTitle",
  },
  {
    title: "证书编号",
    dataIndex: "certNumber",
    align: "center",
    key: "certNumber",
  },
  {
    title: "到期时间",
    dataIndex: "certEndTime",
    align: "center",
    key: "certEndTime",
  },
  {
    title: "证书附件",
    dataIndex: "certUrl",
    align: "center",
    key: "certUrl",
    scopedSlots: { customRender: "certUrl" },
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const specialOperationColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "证件号",
    dataIndex: "certNumber",
    align: "center",
    key: "certNumber",
  },
  {
    title: "初领时间",
    dataIndex: "certFirstTime",
    align: "center",
    key: "certFirstTime",
  },
  {
    title: "有效日期",
    dataIndex: "certEndTime",
    align: "center",
    key: "certEndTime",
  },
  {
    title: "证书附件",
    dataIndex: "certUrl",
    align: "center",
    key: "certUrl",
    scopedSlots: { customRender: "certUrl" },
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const licenceColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "材料名称",
    dataIndex: "fileMaterialName",
    align: "center",
    key: "fileMaterialName",
  },
  {
    title: "附件",
    dataIndex: "fileUrl",
    align: "center",
    key: "fileUrl",
    scopedSlots: { customRender: "fileUrl" },
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const testColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "体检时间",
    dataIndex: "medicalDate",
    align: "center",
    key: "medicalDate",
  },
  {
    title: "结论",
    dataIndex: "medicalResult",
    align: "center",
    key: "medicalResult",
  },
  {
    title: "附件",
    dataIndex: "medicalUrl",
    align: "center",
    key: "medicalUrl",
    scopedSlots: { customRender: "medicalUrl" },
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const workExperiencesColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "项目名称",
    dataIndex: "siteName",
    align: "center",
    key: "siteName",
  },
  {
    title: "原工作单位名称",
    dataIndex: "workUnitName",
    align: "center",
    key: "workUnitName",
  },
  {
    title: "进场日期",
    dataIndex: "startDate",
    align: "center",
    key: "startDate",
  },
  { title: "退场日期", dataIndex: "endDate", align: "center", key: "endDate" },
  {
    title: "职务名称",
    dataIndex: "workType",
    align: "center",
    key: "workType",
  },
  { title: "证明人", dataIndex: "workKind", align: "center", key: "workKind" },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const deviceColumns = [
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
    key: "deviceName",
  },
  {
    title: "设备类型",
    dataIndex: "attendanceTypeName",
    align: "center",
    key: "attendanceTypeName",
  },
  {
    title: "已下发个数",
    dataIndex: "issueNumber",
    align: "center",
    key: "issueNumber",
  },
  {
    title: "状态",
    dataIndex: "deviceState",
    align: "center",
    key: "deviceState",
    scopedSlots: { customRender: "deviceState" },
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
const fileColumns = [
  { title: "序号", dataIndex: "key", align: "center", key: "key" },
  {
    title: "文件名称",
    dataIndex: "fileName",
    align: "center",
    key: "fileName",
  },
  {
    title: "附件",
    dataIndex: "fileUrl",
    align: "center",
    key: "fileUrl",
    scopedSlots: { customRender: "fileUrl" },
  },
  {
    title: "操作",
    align: "center",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  data() {
    return {
      labelCol: 4,
      wrapperCol: 18,
      validator,
      companyMap: [], //单位下拉列表
      companyTypeId: "",
      companyId: "",
      companyTypeMap: [], //单位类型下拉列表
      teamMap: [], //班组下拉
      jobsMap: [], //职务和工种
      nationalList,
      loadData: "",
      //证书
      certificate: {
        columns: certificateColumns,
        visible: false,
        title: "添加证书",
        key: "",
        files: {},
        data: [],
      },
      //特种作业
      specialOperation: {
        columns: specialOperationColumns,
        visible: false,
        title: "添加特种作业",
        key: "",
        files: {},
        data: [],
      },
      setValueStatus: false,
      //证照
      licence: {
        columns: licenceColumns,
        name: "",
        data: [
          {
            key: 1,
            fileMaterialName: "身份证照片正面",
            fileUrl: "",
            fileName: "",
            fileType: 1,
            id: "",
          },
          {
            key: 2,
            fileMaterialName: "身份证照片反面",
            fileUrl: "",
            fileName: "",
            fileType: 2,
            id: "",
          },
          {
            key: 3,
            fileMaterialName: "三级安全教育证明",
            fileUrl: "",
            fileName: "",
            fileType: 3,
            id: "",
          },
        ],
      },
      //合同
      contract: {
        columns: licenceColumns,
        mid: "",
        data: [
          {
            key: 1,
            fileMaterialName: "合同附件",
            fileName: "",
            fileUrl: "",
            fileType: 4,
            id: "",
          },
        ],
      },
      //体检信息
      test: {
        visible: false,
        title: "添加体检",
        key: "",
        columns: testColumns,
        data: [],
        files: {},
      },
      //工作经历
      workExperiences: {
        visible: false,
        title: "添加从业信息",
        key: "",
        columns: workExperiencesColumns,
        data: [],
      },
      //资料文件
      fileData: {
        visible: false,
        title: "添加资料文件",
        key: "",
        columns: fileColumns,
        data: [],
        files: {},
      },
      personnelType: "", //人员类型（管理人员、施工人员）
      labourType: "", //施工人员类型
      teamRelativeId: "", //班组关联id
      //人员拍照
      deviceModal: {
        columns: deviceColumns,
        data: [],
        visible: false,
        title: "人脸照片",
        currentDeviceCode: "",
        type: "",
      },

      //身份证拍照
      idcardModal: {
        visible: false,
        title: "身份证照片",
        currentDeviceCode: "",
      },
      faceModal: {
        visible: false,
        title: "人脸照片",
        currentDeviceCode: "",
      },
      fileModal: {
        visible: false,
        title: "文件拍照",
        currentDeviceCode: "",
        url: "",
      },
      loadTakeImageStatus: false,
      personnelFaceImage: "",
      avatar: "",
      timer: null,
      shoupaiList: [],
      sfzUrl: "",
      ssqList: [],
      ssqList2: [],
      textP: "拍照",
      textP2: "拍照",
      textP3: "拍照",
      isDis: false,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { first: true });
    this.certificateForm = this.$form.createForm(this);
    this.testForm = this.$form.createForm(this);
    this.workExperiencesForm = this.$form.createForm(this);
    this.specialOperationForm = this.$form.createForm(this);
    this.fileDataForm = this.$form.createForm(this);
  },
  components: { upload, previewImage },
  computed: {
    pid() {
      return this.$route.query.id;
    },
    picServer() {
      return this.$store.state.picServer;
    },
    submitLoadding() {
      return this.$store.state.submitLoadding;
    },
  },
  created() {
    this.personnelType = this.$route.query.personnelType || "";
    this.init();
    if (this.pid) {
      this.queryPersonnelDetails();
    } else {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          // maritalStatus: 1,
          sex: 1,
          nation: "汉族",
          // educationLevel: 5,
          liveBuiltBureau: 0,
        });
      });

      this.getCity();

      this.queryTeamDropDownBox(); //班组
      this.queryJobsDropDownBox();
      // this.getUnBindLabelList();
    }
  },
  mounted() {
    this.$nextTick(() => {
      main.load();
    });
    this.form.setFieldsValue({
      inDate: moment(),
    });
  },
  methods: {
    getCity() {
      this.$api.personnelCity().then((res) => {
        if (res.code === 200) {
          this.ssqList = DataTransformUtils.toTree(
            res.data,
            "id",
            "parentId",
            "children"
          );
          this.ssqList2 = res.data;
          console.log(res);
        }
      });
    },
    quxiao() {
      this.idcardModal.visible = false;
      main.stop_preview();
    },
    quxiao2() {
      this.faceModal.visible = false;
      main.stop_preview();
    },
    quxiao3() {
      this.fileModal.visible = false;
      main.stop_preview();
    },
    paizhao() {
      if (this.textP == "拍照") {
        this.textP = "重新拍照";
        main.stop_preview();
      } else {
        this.textP = "拍照";
        main.start_preview();
        main.RotateCrop(true);
      }
      main.readidcard();
    },
    paizhao2() {
      if (this.textP2 == "拍照") {
        this.textP2 = "重新拍照";
        main.stop_preview();
      } else {
        this.textP2 = "拍照";
        main.start_preview();
      }
    },
    paizhao3() {
      if (this.textP3 == "拍照") {
        this.textP3 = "重新拍照";
        main.stop_preview();
      } else {
        this.textP3 = "拍照";
        main.start_preview();
      }
    },

    // this.idcardModal.visible = true;
    //   main.changedev(0);
    //   main.start_preview();
    //   main.RotateCrop(true);

    baocun() {
      var myimg = document.getElementById("myCanvas");
      if (this.textP != "拍照") {
        console.log(myimg);

        let idcardInformation = localStorage.getItem("idcardInformation");
        console.log("=================");
        console.log(idcardInformation);
        idcardInformation = JSON.parse(idcardInformation);
        console.log(idcardInformation);
        if (idcardInformation && idcardInformation.ret == 0) {
          let ssqList2 = this.ssqList2;
          console.log(ssqList2);
          let cityName = idcardInformation.data.GrantDept;
          let i = cityName.indexOf("县") + 1;
          cityName = cityName.substring(0, i);
          if (i == 0) {
            cityName = idcardInformation.data.Address;
            i = cityName.indexOf("区") + 1;
            let s = cityName.indexOf("市") + 1;
            cityName = cityName.substring(s, i);
          }
          console.log(cityName);

          let cityCode = "";
          for (let i = 0; i < ssqList2.length; i++) {
            if (ssqList2[i].areaName == cityName) {
              cityCode = ssqList2[i].id;
            }
          }
          this.$nextTick(() => {
            this.form.setFieldsValue({
              personnelName: idcardInformation.data.Name,
              idCard: idcardInformation.data.IDCardNo,
              issuingAuthority: idcardInformation.data.GrantDept,
              endValid: idcardInformation.data.ValidDate.split("-"),
              endValid2: idcardInformation.data.ValidDate.split("-")[0],
              idCardValidityStatus:
                idcardInformation.data.ValidDate.indexOf("长期") > 0
                  ? true
                  : false,
              nation: idcardInformation.data.Nation,
              sex: idcardInformation.data.Sex == "男" ? 1 : 2,
              residenceAddress: idcardInformation.data.Address,

              personnelCityId: DataTransformUtils._GetIntactIds(
                this.ssqList2,
                cityCode
              ),
            });
            this.setValueStatus =
              idcardInformation.data.ValidDate.indexOf("长期") > 0
                ? true
                : false;
          });
        }
        let arr = this.dataURLtoFile(myimg.src, "idPhoto.png");
          arr.uid = "vc-upload-" + Date.parse(new Date());
          //文件上传对象
          let formData = new FormData();
          formData.append("file", arr);
          this.$api.uploadFile(formData).then((res) => {
            if (res.code === 200) {
              this.sfzUrl = this.picServer + res.data.codeUrl;
              this.idcardModal.visible = false;
            }
          });
        this.queryPersonnelIsBlack(idcardInformation.data.IDCardNo);
        console.log('============',this.isDis);
        // if (this.isDis == false) {
        //   let arr = this.dataURLtoFile(myimg.src, "idPhoto.png");
        //   arr.uid = "vc-upload-" + Date.parse(new Date());
        //   //文件上传对象
        //   let formData = new FormData();
        //   formData.append("file", arr);
        //   this.$api.uploadFile(formData).then((res) => {
        //     if (res.code === 200) {
        //       this.sfzUrl = this.picServer + res.data.codeUrl;
        //       this.idcardModal.visible = false;
        //     }
        //   });
        // }
      } else {
        this.$message.error("请拍照后保存");
      }
    },
    baocun2() {
      var myimg = document.getElementById("myCanvas");
      if (this.textP2 != "拍照") {
        let arr = this.dataURLtoFile(myimg.src, "idPhoto.png");
        arr.uid = "vc-upload-" + Date.parse(new Date());
        //文件上传对象
        let formData = new FormData();
        formData.append("file", arr);
        this.$api.uploadFile(formData).then((res) => {
          if (res.code === 200) {
            this.avatar = this.picServer + res.data.codeUrl;
            this.faceModal.visible = false;
            this.$nextTick(() => {
              this.form.setFieldsValue({
                avatar: this.picServer + res.data.codeUrl,
              });
            });
          }
        });
      } else {
        this.$message.error("请拍照后保存");
      }
    },
    baocun3() {
      var myimg = document.getElementById("myCanvas");
      if (this.textP3 != "拍照") {
        let arr = this.dataURLtoFile(myimg.src, "idPhoto.png");
        arr.uid = "vc-upload-" + Date.parse(new Date());
        let url = this.fileModal.url;
        //文件上传对象
        let formData = new FormData();
        formData.append("file", arr);
        this.$api.uploadFile(formData).then((res) => {
          if (res.code === 200) {
            this.textP3 = "拍照";
            this.fileModal.visible = false;
            // this.avatar = this.picServer + res.data.codeUrl;
            if (url == "fileDataUrl") {
              this.fileData.files = res.data;
              this.fileData.files.url = res.data.codeUrl;
              this.fileModal.visible = false;
              this.$nextTick(() => {
                this.fileDataForm.setFieldsValue({
                  fileUrl: res.data.codeUrl,
                });
              });
            } else if (url == "sfzUrl") {
              let newMap = [...this.licence.data];
              newMap.forEach((item) => {
                if (item.fileMaterialName === this.licence.name) {
                  item.fileUrl = res.data.codeUrl;
                  item.fileName = res.data.fileName;
                }
              });
              this.licence.data = newMap;
            } else if (url == "hetUrl") {
              this.contract.data[0].fileUrl = res.data.codeUrl;
              this.contract.data[0].fileName = res.data.fileName;
            } else if (url == "certUrl") {
              this.certificate.files = res.data;
              this.certificate.files.url = res.data.codeUrl;
              this.$nextTick(() => {
                this.certificateForm.setFieldsValue({
                  [url]: res.data.codeUrl,
                });
              });
            } else if (url == "medicalUrl") {
              this.test.files = res.data;
              this.test.files.url = res.data.codeUrl;
              this.$nextTick(() => {
                this.testForm.setFieldsValue({
                  [url]: res.data.codeUrl,
                });
              });
            }
          }
        });
      } else {
        this.$message.error("请拍照后保存");
      }
    },
    renlianSbie() {
      this.faceModal.visible = true;
      main.changedev(1);
      main.start_preview();
    },
    zpSbie() {
      this.idcardModal.visible = true;
      localStorage.setItem("idcardInformation", "");
      main.changedev(0);
      main.start_preview();
      main.RotateCrop(true);
    },
    fileSbie(url, name) {
      if (name) {
        this.licence.name = name;
      }
      this.fileModal.visible = true;
      this.fileModal.url = url;
      main.changedev(0);
      main.start_preview();
      main.RotateCrop(true);
    },
    init() {
      this.queryCompanyDropDownBox(); //单位
      this.queryTypeDropDownBox(); //单位类型
      // this.getpersonnelCityList(); //省市区列表
    },
    // getpersonnelCityList() {

    // },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    //设备列表
    queryGateMachineList(type) {
      this.$api.queryGateMachineList({ type: 1 }).then((res) => {
        if (res.code === 200) {
          this.deviceModal.data = res.data || [];
          this.deviceModal.data = this.deviceModal.data.filter((item) => {
            if (type) return item.attendanceType == type;
            return item;
          });
          this.deviceModal.data.forEach((item) => {
            item.key = item.id;
            item.attendanceTypeName =
              item.attendanceType == 1 ? "门禁设备" : "考勤设备";
          });
        }
      });
    },
    //单位下拉
    queryCompanyDropDownBox() {
      // this.$api.queryCompanyDropDownBox().then(res =>{
      //     if(res.code === 200){
      //         this.companyMap = res.data || []
      //     }
      // })

      this.$api.queryCompanyDropDownBox().then((res) => {
        if (res.code === 200) {
          console.log(res);
          this.companyMap = res.data || [];
        }
      });
    },
    changqiCg(value) {
      console.log(value.target.checked);
      if (value.target.checked) {
        this.form.setFieldsValue({
          endValid: [],
          endValid2: "",
        });
      }
      this.setValueStatus = value.target.checked;
    },
    // 将输入的内容与显示的内容进行匹配
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0;
    },
    changeCompany(companyId) {
      this.companyId = companyId;
      let company = this.companyMap.filter((item) => item.id == companyId)[0];
      this.companyTypeId = company.companyTypeId;
      this.queryJobsDropDownBox();
      this.queryTeamDropDownBox();
      this.$nextTick(() => {
        let formData = {
          socialCreditCode: company.socialCreditCode,
          companyTypeId: "" + company.companyTypeId,
          positionWorkId: "",
        };
        if (this.personnelType == 2 && this.labourType == 3) {
          formData.teamId = "";
        }
        this.form.setFieldsValue(formData);
      });
    },
    //验证是否是黑名单
    checkIsblick() {
      let idCard = this.form.getFieldValue("idCard");
      this.queryPersonnelIsBlack(idCard);
    },
    queryPersonnelIsBlack(value) {
      this.$api.queryPersonnelIsBlack(value).then((res) => {
        if (res.code === 200) {
          console.log('=============',res.data);
          if (res.data == true) {
            this.isDis = true;
            this.$store.commit("SET_SUBMITLOADDING", true);
            this.$message.error("黑名单人员禁止入场");
          } else {
            this.isDis = false;
            this.$store.commit("SET_SUBMITLOADDING", false);
          }
        } else {
          this.isDis = false;
          this.$store.commit("SET_SUBMITLOADDING", false);
          this.$message.error(res.msg);
        }
      });
    },
    //单位类型下拉
    queryTypeDropDownBox() {
      this.$api.queryTypeDropDownBox().then((res) => {
        if (res.code === 200) {
          this.companyTypeMap = res.data || [];
        }
      });
    },
    capture() {
      console.log("asdasdasd");
    },
    //班组下拉
    queryTeamDropDownBox() {
      let params = {};
      if (this.companyId) {
        params.companyId = this.companyId;
      }
      this.$api.queryTeamDropDownBox(params).then((res) => {
        if (res.code === 200) {
          this.teamMap = res.data || [];
        }
      });
    },
    //获取未绑定的手环/胸卡
    // getUnBindLabelList() {
    //   this.$api.getUnBindLabelList().then((res) => {
    //     if (res.code === 200) {
    //       console.log(res);
    //       this.shoupaiList = res.data || [];
    //     }
    //   });
    // },
    //人脸拍照
    showDeviceModal() {
      this.queryGateMachineList();
      this.deviceModal.visible = true;
    },
    queryPhotograph(deviceCode) {
      if (this.loadTakeImageStatus) return;
      this.currentDeviceCode = deviceCode;
      this.personnelFaceImage = "";
      this.$api.queryPhotograph({ deviceCode }).then((res) => {
        if (res.code === 200) {
          this.loadTakeImageStatus = true;
          this.getTakeImage(res.data);
        }
      });
    },
    getTakeImage(personnelId) {
      let _that = this;
      _that.timer = setInterval(() => {
        _that.$api.getTakeImage({ personnelId }).then((res) => {
          if (res.code === 200) {
            let photoBase64 = (res.data && res.data.photoBase64) || {};
            if (photoBase64.codeUrl) {
              _that.loadTakeImageStatus = false;
              _that.personnelFaceImage = photoBase64.codeUrl;
              clearInterval(_that.timer);
            }
          }
        });
      }, 1000);
      setTimeout(() => {
        _that.$message.error("人脸录入失败，请重新录入");
        clearInterval(_that.timer);
      }, 1200000);
    },
    cancelTakeAvatar() {
      this.loadTakeImageStatus = false;
      this.personnelFaceImage = "";
      clearInterval(this.timer);
    },
    saveAvatar() {
      if (!this.personnelFaceImage) {
        this.$message.warning("设备正在录入人脸中...");
        return false;
      }

      this.$nextTick(() => {
        let personnelFaceImage = this.personnelFaceImage;
        this.form.setFieldsValue({
          avatar: personnelFaceImage,
        });
        this.avatar = this.picServer + personnelFaceImage;
        this.personnelFaceImage = "";
        this.deviceModal.visible = false;
      });
    },
    //编辑时人员详细信息回填数据
    queryPersonnelDetails() {
      this.$api.personnelCity().then((res) => {
        if (res.code === 200) {
          this.ssqList = DataTransformUtils.toTree(
            res.data,
            "id",
            "parentId",
            "children"
          );
          this.ssqList2 = res.data;
          console.log(this.ssqList2);

          this.$api.queryPersonnelDetails({ id: this.pid }).then((res) => {
            if (res.code === 200) {
              let _data = res.data || {};
              console.log(_data);

              let personnelInfo = _data.personnelInfo || [];
              this.personnelType = personnelInfo.personnelType == 1 ? 1 : 2;
              this.labourType = personnelInfo.personnelType;
              this.avatar = personnelInfo.avatar
                ? personnelInfo.avatar.includes("http")
                  ? personnelInfo.avatar
                  : this.picServer + personnelInfo.avatar
                : "";

              this.sfzUrl = personnelInfo.idCardPhoto
                ? personnelInfo.idCardPhoto.includes("http")
                  ? personnelInfo.idCardPhoto
                  : this.picServer + personnelInfo.idCardPhoto
                : "";

              this.companyTypeId = _data.companyTypeId;
              this.companyId = personnelInfo.companyId;

              this.queryTeamDropDownBox(); //班组
              this.queryJobsDropDownBox(); //工种

              //有效日期
              let startValid = personnelInfo.startValid
                ? moment(personnelInfo.startValid)
                : null;
              let endValid = personnelInfo.endValid
                ? moment(personnelInfo.endValid)
                : null;
              let formData = {
                avatar: personnelInfo.avatar,
                idCardPhoto: personnelInfo.idCardPhoto,
                companyId: personnelInfo.companyId,
                socialCreditCode: _data.socialCreditCode,
                companyTypeId: _data.companyTypeId,
                educationLevel: personnelInfo.educationLevel,
                familyAddress: personnelInfo.familyAddress,
                idCard: personnelInfo.idCard,
                issuingAuthority: personnelInfo.issuingAuthority,
                liveBuiltBureau: personnelInfo.liveBuiltBureau,
                maritalStatus: personnelInfo.maritalStatus,
                nation: personnelInfo.nation,
                partyMember: personnelInfo.partyMember,
                payBankname: personnelInfo.payBankname,
                payCardnum: personnelInfo.payCardnum,
                payBranch: personnelInfo.payBranch,

                personnelName: personnelInfo.personnelName,
                phoneNumber: personnelInfo.phoneNumber,
                residenceAddress: personnelInfo.residenceAddress,
                sex: personnelInfo.sex,
                emergencyContact: personnelInfo.emergencyContact,
                contactNumber: personnelInfo.contactNumber,
                positionWorkId: personnelInfo.positionWorkId,
                inDate: personnelInfo.inDate
                  ? moment(personnelInfo.inDate)
                  : null,
                endValid: [startValid, endValid],
                endValid2: startValid,
                personnelCityId: DataTransformUtils._GetIntactIds(
                  this.ssqList2,
                  personnelInfo.personnelCityId
                ),
                idCardValidityStatus:
                  personnelInfo.idCardValidityStatus == 1 ? true : false,
                remarks: personnelInfo.remarks,
              };

              console.log(formData.idCardValidityStatus);

              this.setValueStatus =
                personnelInfo.idCardValidityStatus == 1 ? true : false;

              //班组
              if (this.personnelType == 2) {
                formData.personnelType = personnelInfo.personnelType;
                if (this.labourType == 3) {
                  formData.teamId = _data.teamId;
                  formData.isLeader = _data.isLeader;
                  this.teamRelativeId = _data.personnelTeamId;
                }
              }
              //证书
              this.certificate.data = _data.personnelCert || [];
              this.certificate.data.forEach((item, key) => {
                item.key = key + 1;
              });
              //证照
              let personnelFiles = _data.personnelFile || [];
              this.licence.data.forEach((item) => {
                personnelFiles.forEach((v) => {
                  if (v.fileMaterialName === item.fileMaterialName) {
                    item.fileUrl = v.fileUrl;
                    item.fileName = v.fileName;
                    item.id = v.id;
                  }
                });
              });
              //合同
              let personnelContract =
                (_data.personntlContract &&
                  _data.personntlContract.personnelContract) ||
                {};
              let personnelFile =
                (_data.personntlContract &&
                  _data.personntlContract.personnelFiles) ||
                [];
              formData.contractNo = personnelContract.contractNo || "";
              formData.handleDate = personnelContract.handleDate
                ? moment(personnelContract.handleDate)
                : null;
              formData.endDate = personnelContract.endDate
                ? moment(personnelContract.endDate)
                : null;
              this.contract.mid = personnelContract.id;
              personnelFile.forEach((item) => {
                this.contract.data.forEach((v) => {
                  if (v.fileMaterialName === item.fileMaterialName) {
                    v.fileUrl = item.fileUrl;
                    v.fileName = item.fileName;
                    v.id = item.id;
                  }
                });
              });
              //体检信息
              this.test.data = _data.personntlMedical || [];
              this.test.data.forEach((item, ind) => {
                item.key = ind + 1;
              });
              //从业信息
              this.workExperiences.data = _data.workExperience || [];
              this.workExperiences.data.forEach((item, ind) => {
                item.key = ind + 1;
              });
              //资料文件
              this.fileData.data = _data.documentList || [];
              this.fileData.data.forEach((item, ind) => {
                item.key = ind + 1;
              });

              //特种作业
              this.specialOperation.data = _data.personnelCert || [];
              this.specialOperation.data.forEach((item, key) => {
                item.key = key + 1;
              });
              setTimeout(() => {
                this.form.setFieldsValue(formData);
              }, 500);
            }
          });
        }
      });
    },
    //职务工种
    queryJobsDropDownBox() {
      let params = {};
      params.type = this.personnelType == 1 || this.labourType == 2 ? 2 : 1;
      if (this.companyTypeId) {
        params.companyTypeId = this.companyTypeId;
      }
      this.$api.queryJobsDropDownBox(params).then((res) => {
        if (res.code === 200) {
          this.jobsMap = res.data || [];
        }
      });
    },
    onChange(value) {
      console.log(value);
      this.form.setFieldsValue({
        personnelCityId: value,
      });
    },
    changePersonType(labourType) {
      this.labourType = labourType;
      this.queryJobsDropDownBox();
    },
    //特种作业modal
    showSpecialOperationModal(list) {
      this.specialOperation.visible = true;
      this.specialOperation.key = list ? list.key : "";
      this.specialOperation.files = {};
      if (list) {
        let formData = {
          certFirstTime: list.certFirstTime ? moment(list.certFirstTime) : null,
          certEndTime: [
            list.certStartTime ? moment(list.certStartTime) : null,
            list.certEndTime ? moment(list.certEndTime) : null,
          ],
          certNumber: list.certNumber,
          certUrl: list.certUrl,
        };
        this.specialOperation.files.fileUrl = list.certUrl;
        this.specialOperation.files.url = list.certUrl;
        this.$nextTick(() => {
          this.specialOperationForm.setFieldsValue(formData);
        });
      }
    },
    getSpecialOperationFile(file) {
      this.specialOperation.files = file;
      this.$nextTick(() => {
        this.specialOperationForm.setFieldsValue({
          certUrl: file.codeUrl,
        });
      });
    },
    saveSpecialOperation() {
      this.specialOperationForm.validateFields((err, formData) => {
        if (!err) {
          this.specialOperation.visible = false;
          formData.certFirstTime = formData.certFirstTime
            ? formData.certFirstTime.format("YYYY-MM-DD")
            : null;
          formData.certStartTime = formData.certEndTime.length
            ? formData.certEndTime[0].format("YYYY-MM-DD")
            : null;
          formData.certEndTime = formData.certEndTime.length
            ? formData.certEndTime[1].format("YYYY-MM-DD")
            : null;
          if (this.specialOperation.key) {
            this.specialOperation.data.forEach((item) => {
              if (item.key === this.specialOperation.key) {
                item.certFirstTime = formData.certFirstTime;
                item.certStartTime = formData.certStartTime;
                item.certEndTime = formData.certEndTime;
                item.certNumber = formData.certNumber;
                item.certUrl = formData.certUrl;
              }
            });
          } else {
            formData.key = this.certificate.data.length + 1;
            this.specialOperation.data.push(formData);
          }
        }
      });
    },
    deleteSpecialOperation(list) {
      this.specialOperation.data = this.specialOperation.data.filter(
        (item) => item.key != list.key
      );
    },
    //证书
    showCertificateModal(list) {
      this.certificate.visible = true;
      this.certificate.key = list ? list.key : "";
      this.certificate.files = {};
      if (list) {
        let formData = {
          certEndTime: list.certEndTime ? moment(list.certEndTime) : null,
          professionName: list.professionName,
          jobTitle: list.jobTitle,
          certNumber: list.certNumber,
          certUrl: list.certUrl,
        };
        this.certificate.files.fileUrl = list.certUrl;
        this.certificate.files.url = list.certUrl;
        this.$nextTick(() => {
          this.certificateForm.setFieldsValue(formData);
        });
      }
    },
    getCertificateFile(file) {
      this.certificate.files = file;
      this.$nextTick(() => {
        this.certificateForm.setFieldsValue({
          certUrl: file.codeUrl,
        });
      });
    },
    saveCertificate() {
      this.certificateForm.validateFields((err, formData) => {
        if (!err) {
          this.certificate.visible = false;

          formData.certEndTime = formData.certEndTime.format("YYYY-MM-DD");

          if (this.certificate.key) {
            this.certificate.data.forEach((item) => {
              if (item.key == this.certificate.key) {
                item.certEndTime = formData.certEndTime;
                item.certNumber = formData.certNumber;
                item.jobTitle = formData.jobTitle;
                item.professionName = formData.professionName;
                item.certUrl = formData.certUrl;
              }
            });
          } else {
            formData.key = this.certificate.data.length + 1;
            this.certificate.data.push(formData);
          }
        }
      });
    },
    uploadFile(file) {
      // console.log(file[file.length-1])

      let items = file[file.length - 1];
      (this.avatar = items.codeUrl
        ? items.codeUrl.includes("http")
          ? items.codeUrl
          : this.picServer + items.codeUrl
        : ""),
        this.$nextTick(() => {
          this.form.setFieldsValue({
            avatar: items.codeUrl,
          });
        });
    },
    uploadFile2(file) {
      let items = file[file.length - 1];
      (this.sfzUrl = items.codeUrl
        ? items.codeUrl.includes("http")
          ? items.codeUrl
          : this.picServer + items.codeUrl
        : ""),
        this.$nextTick(() => {
          this.form.setFieldsValue({
            idCardPhoto: items.codeUrl,
          });
        });
    },
    deleteCertificate(list) {
      this.certificate.data = this.certificate.data.filter(
        (item) => item.key != list.key
      );
    },

    //证照
    getLicenceFile(file) {
      let newMap = [...this.licence.data];
      newMap.forEach((item) => {
        if (item.fileMaterialName === this.licence.name) {
          item.fileUrl = file.codeUrl;
          item.fileName = file.fileName;
        }
      });
      this.licence.data = newMap;
    },
    deleteLicenceFile(list) {
      this.licence.data.forEach((item) => {
        if (item.key == list.key) {
          item.fileUrl = "";
          item.fileName = "";
        }
      });
    },
    //合同
    getContractFile(file) {
      this.contract.data[0].fileUrl = file.codeUrl;
      this.contract.data[0].fileName = file.fileName;
    },
    deleteContractFile() {
      this.contract.data.forEach((item) => {
        item.fileUrl = "";
        item.fileName = "";
      });
    },
    //体检
    showTestModal(list) {
      this.test.visible = true;
      this.test.key = list ? list.key : "";
      this.test.files = {};
      if (list) {
        let formData = {
          medicalDate: list.medicalDate ? moment(list.medicalDate) : null,
          medicalResult: list.medicalResult,
          medicalUrl: list.medicalUrl,
        };
        this.test.files = {
          url: list.medicalUrl,
          fileUrl: list.medicalUrl,
        };
        this.$nextTick(() => {
          this.testForm.setFieldsValue(formData);
        });
      }
    },
    saveTest() {
      this.testForm.validateFields((err, formData) => {
        if (!err) {
          this.test.visible = false;
          formData.medicalDate = formData.medicalDate
            ? formData.medicalDate.format("YYYY-MM-DD")
            : null;
          formData.fileName = this.test.files.fileName;
          if (this.test.key) {
            this.test.data.forEach((item) => {
              if (item.key == this.test.key) {
                item.medicalDate = formData.medicalDate;
                item.medicalResult = formData.medicalResult;
                item.medicalUrl = formData.medicalUrl;
                item.fileName = formData.fileName;
              }
            });
          } else {
            formData.key = this.test.data.length + 1;
            this.test.data.push(formData);
          }
        }
      });
    },
    getTestFile(file) {
      this.test.files = file;
      this.$nextTick(() => {
        this.testForm.setFieldsValue({
          medicalUrl: file.codeUrl,
        });
      });
    },
    deleteTest(list) {
      this.test.data = this.test.data.filter(
        (item) => item.medicalUrl != list.medicalUrl
      );
    },

    //从业经历
    showWorkExperiencesModal(list) {
      this.workExperiences.visible = true;
      this.workExperiences.key = list ? list.key : "";

      if (list) {
        let formData = {
          startDate: list.startDate ? moment(list.startDate) : null,
          endDate: list.endDate ? moment(list.endDate) : null,
          workType: list.workType,
          workKind: list.workKind,
          workUnitName: list.workUnitName,
          siteName: list.siteName,
        };
        this.$nextTick(() => {
          this.workExperiencesForm.setFieldsValue(formData);
        });
      }
    },
    saveWorkExperiences() {
      this.workExperiencesForm.validateFields((err, formData) => {
        if (!err) {
          this.workExperiences.visible = false;

          formData.startDate = formData.startDate.format("YYYY-MM-DD");
          formData.endDate = formData.endDate.format("YYYY-MM-DD");
          if (this.workExperiences.key) {
            let newList = [...this.workExperiences.data];
            newList.forEach((item) => {
              if (item.key == this.workExperiences.key) {
                item.workType = formData.workType;
                item.workKind = formData.workKind;
                item.startDate = formData.startDate;
                item.endDate = formData.endDate;
                item.workUnitName = formData.workUnitName;
                item.siteName = formData.siteName;
              }
            });
            this.workExperiences.data = newList;
          } else {
            formData.key = this.workExperiences.data.length + 1;
            this.workExperiences.data.push(formData);
          }
        }
      });
    },
    deleteWorkExperiences(list) {
      this.workExperiences.data = this.workExperiences.data.filter(
        (item) => item.key != list.key
      );
    },
    //资料文件
    showFileDataModal(list) {
      this.fileData.visible = true;
      this.fileData.key = list ? list.key : "";
      this.fileData.files = {};
      if (list) {
        let formData = {
          fileName: list.fileName,
          remarks: list.remarks,
          fileUrl: list.fileUrl,
        };
        this.fileData.files = {
          url: list.fileUrl,
          fileUrl: list.fileUrl,
        };
        this.$nextTick(() => {
          this.fileDataForm.setFieldsValue(formData);
        });
      }
    },
    saveFileData() {
      this.fileDataForm.validateFields((err, formData) => {
        if (!err) {
          this.fileData.visible = false;
          formData.fileOriginName = this.fileData.files.fileName;
          // formData.fileRemark = this.fileData.files.fileRemark;
          if (this.fileData.key) {
            this.fileData.data.forEach((item) => {
              if (item.key == this.fileData.key) {
                item.remarks = formData.remarks;
                item.fileUrl = formData.fileUrl;
                item.fileName = formData.fileName;
              }
            });
          } else {
            formData.key = this.fileData.data.length + 1;
            this.fileData.data.push(formData);
          }
        }
      });
    },
    getFileDataFile(file) {
      this.fileData.files = file;
      this.$nextTick(() => {
        this.fileDataForm.setFieldsValue({
          fileUrl: file.codeUrl,
        });
      });
    },
    deleteFileData(list) {
      this.fileData.data = this.fileData.data.filter(
        (item) => item.fileUrl != list.fileUrl
      );
    },
    //在入场绑定设备
    personnelReEntrance(id) {
      this.$api.personnelReEntrance(id).then(() => {});
    },

    //添加编辑提交表单
    submit() {
      this.form.validateFields((err, formData) => {
        if (!err) {
          this.$store.commit("SET_SUBMITLOADDING", true);
          //时间格式化
          formData.inDate = formData.inDate
            ? formData.inDate.format("YYYY-MM-DD")
            : null;

          //人员类型
          formData.personnelType =
            this.personnelType == 1 ? this.personnelType : this.labourType;
          if (this.pid) {
            formData.id = this.pid;
          }

          //证照
          let personnelFiles = this.licence.data;
          console.log(personnelFiles);
          if (!this.setValueStatus) {
            let dataMap = [...formData.endValid];

            formData.endValid = dataMap.length
              ? typeof dataMap[1] == "object"
                ? dataMap[1].format("YYYY-MM-DD")
                : String(dataMap[1]).slice(0, 4) +
                  "-" +
                  String(dataMap[1]).slice(4, 6) +
                  "-" +
                  String(dataMap[1]).slice(6)
              : null;
            formData.startValid = dataMap.length
              ? typeof dataMap[0] == "object"
                ? dataMap[0].format("YYYY-MM-DD")
                : String(dataMap[0]).slice(0, 4) +
                  "-" +
                  String(dataMap[0]).slice(4, 6) +
                  "-" +
                  String(dataMap[0]).slice(6)
              : null;
          } else {
            let dataMap = formData.endValid2;

            // formData.startValid = dataMap ? dataMap.format("YYYY-MM-DD") : null;
            formData.startValid = dataMap
              ? typeof dataMap == "object"
                ? dataMap.format("YYYY-MM-DD")
                : String(dataMap).slice(0, 4) +
                  "-" +
                  String(dataMap).slice(4, 6) +
                  "-" +
                  String(dataMap).slice(6)
              : null;
            formData.endValid = "";
          }

          //合同
          let personnelContract = {
            contractNo: formData.contractNo,
            handleDate: formData.handleDate
              ? formData.handleDate.format("YYYY-MM-DD")
              : null,
            endDate: formData.endDate
              ? formData.endDate.format("YYYY-MM-DD")
              : null,
            contractUrl: this.contract.data[0].fileUrl || "",
            id: this.contract.mid,
          };

          //合同附件
          let personnelFile = this.contract.data[0];
          formData.idCardValidityStatus = formData.idCardValidityStatus ? 1 : 0;
          // 短期需要时间
          // if (formData.idCardValidityStatus == 0) {
          // }

          if (formData.personnelCityId && formData.personnelCityId.length > 0) {
            formData.personnelCityId =
              formData.personnelCityId[formData.personnelCityId.length - 1];
          }

          formData.idCardPhoto = this.sfzUrl
            ? this.sfzUrl.includes("http")
              ? this.sfzUrl.slice(this.picServer.length)
              : this.sfzUrl
            : "";

          formData.avatar = formData.avatar
            ? formData.avatar.includes("http")
              ? formData.avatar.slice(this.picServer.length)
              : formData.avatar
            : "";
          console.log(formData.avatar);
          let params = {
            personnel: formData,
            personnelContract,
            personnelFile,
            personnelFiles,
          };
          if (this.personnelType == 1) {
            //证书
            if (this.certificate.data.length) {
              params.personnelCerts = this.certificate.data;
            }
          } else {
            if (this.labourType == 3) {
              //特总作业
              if (this.specialOperation.data.length) {
                params.personnelCert = this.specialOperation.data[0];
              }
              //班组
              if (formData.isLeader || formData.teamId) {
                params.personnelTeam = {
                  id: this.teamRelativeId,
                  isLeader: formData.isLeader,
                  teamId: formData.teamId,
                };
              }
            }
          }

          //体检信息
          if (this.test.data.length) {
            params.personnelMedicals = this.test.data;
          }
          //工作经历
          if (this.workExperiences.data.length) {
            params.workExperiences = this.workExperiences.data;
          }

          //资料文件
          if (this.fileData.data.length) {
            params.documentList = this.fileData.data;
          }

          let actionType = this.$route.query.actionType;

          let apiName =
            actionType == 3
              ? "personnelEntrance"
              : this.pid
              ? "updatePersonnel"
              : "insertPersonnel";

          console.log(params);

          this.$api[apiName](params).then((res) => {
            console.log("res", res);
            if (res.code === 200) {
              if(apiName=='personnelEntrance'){
                this.personnelReEntrance(this.pid);
              }
              this.queryPersonnelDetails();
              this.$router.back();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          let id =
            Object.keys(err)[0] === "avatar"
              ? "companyTypeId"
              : Object.keys(err)[0];
          if (id === "inDate") {
            this.$refs[id].focus();
          } else {
            document.getElementById(id).focus();
          }
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.timer);
    main.stop_preview();
  },
};
</script>

<style lang="scss" scoped>
// 空状态
.ant-empty {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

.face-photo-wrap {
  height: 120px;
  width: 80px;
  text-align: center;
  color: #1071fa;
  .icon-wrap {
    display: inline-block;
    height: 100px;
    width: 80px;
    line-height: 100px;
    border: 1px dotted #2f538f;
    border-radius: 4px;
    overflow: hidden;
  }
  .text {
    display: block;
    line-height: 16px;
    margin-top: -10px;
  }
}
.take-face-image {
  margin-top: 72px;
  height: 240px;
  width: 180px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  img {
    width: 100%;
    line-height: 180px;
    max-height: 180px;
  }
}
.gpBtn {
  padding: 22px 15px 40px 15px;
  margin-right: 10px;
  background: rgba(28, 61, 108, 0.5);
  border: 1px solid rgba(63, 114, 186, 0.5);
  color: #fff;
}
.gpBtnCard {
  background: #1c3d6c;
}
.quanzhong {
  z-index: 999999999;
  position: relative;
}
.bankuai {
  // background: red;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 1000;
  overflow: auto;
  outline: 0;
  z-index: 9999999;
}
</style>
