// 校验
const Validator = (function(){
    let validatorConfing = {
        //身份证校验
        idCardReg(r,v,callback){
            let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            if(v && !reg.test(v)){
                callback('请输入正确的身份证号码');
            }else{
                callback();
            }
        },
        //邮箱地址校验
        // emailReg(r,v,callback){
        //     let reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
        //     if(v && !reg.test(v)){
        //         callback('请输入正确的邮箱地址');
        //     }else{
        //         callback();
        //     }
        // },
        //手机号码校验
        phoneReg(r,v,callback){
            let reg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/
            if(v && !reg.test(v)){
                callback('请输入正确的手机号码');
            }else{
                callback();
            }
        },
        ///^(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-HJ-NP-Z]{1}(?:(?:[0-9]{5}[DF])|(?:[DF](?:[A-HJ-NP-Z0-9])[0-9]{4})))|(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9 挂学警港澳]{1})$/
        carNumberReg(r,v,callback){
            let reg = /^(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-HJ-NP-Z]{1}(?:(?:[0-9]{5}[DF])|(?:[DF](?:[A-HJ-NP-Z0-9])[0-9]{4})))|(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9 挂学警港澳]{1})$/
            if(v && !reg.test(v)){
                callback('请输入正确的车牌号码');
            }else{
                callback();
            }
        },
        //中文名称校验
        chineseReg(r,v,callback){
            let reg = /^[\u4E00-\u9FA5]{1,100}$/
            if(v && !reg.test(v)){
                callback('请输入中文名称');
            }else{
                callback();
            }
        },
        chineseAndNumberReg(r,v,callback){
            let reg = /^[\u4e00-\u9fa50-9]+$/
            if(v && !reg.test(v)){
                callback('请输入中文或数字');
            }else{
                callback();
            }
        },
        //金额 /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
        moneyReg(r,v,callback){
            let reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/
            if(v && !reg.test(v)){
                callback('请输入金额(保留两位小数)');
            }else if(v && v.length > 16){
                callback('最大输入16位字符');
            }else{
                callback()
            }
        },
        //正整数 /[^/d]/g
        numberReg(r,v,callback){
            let reg = /[^/d]/g
            if(v && !reg.test(v)){
                callback('请输入数字');
            }else{
                callback();
            }
        },
        normalInputReg(r,v,callback){
            let reg = /^[0-9a-zA-Z_]{1,}$/
            if(v && !reg.test(v)){
                callback('请输入数字、字母或下划线');
            }else{
                callback();
            }
        },
        // 测试ip地址
        testIP(r,v,callback){
            let reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
            if(v && !reg.test(v)){
                callback('请输入正确的IP');
            }else{
                callback();
            }
        }
    }
    return validatorConfing
})()
export default Validator