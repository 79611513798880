
var ws = "";
var g_bConnectWS = false;
var g_splitFlag = "$@$";
var g_bClosed = false; //是否被关闭了
var g_index = 0;      //当前高拍仪设备索引
var i = 0

//开启webSocket
function StartWebSocket() {
	var url = "ws://localhost:37896";
	if ('WebSocket' in window) {
		ws = new WebSocket(url);
	}
	// else if('MozWebSocket' in window){
	//     ws = new MozWebSocket(url);
	// }
	else {
		alert("浏览器版本过低，请升级您的浏览器。\r\n浏览器要求：IE10+/Chrome14+/FireFox7+/Opera11+");
	}
	console.log(ws)

	ws.onopen = function () {
		g_bConnectWS = true;
		sendInitMsg();//初始化
		g_bClosed = false;
	};


	ws.onmessage = function (evt) {
		if (typeof (evt.data) == "string") {

			var str = evt.data;
			console.log('连接成功')

			if (str.length <= 0) {

				return;
			}

			if (str.indexOf("@|@") > 0) {
				initDev(str);
				return;
			}

			//读取身份证显示结果
			if (str.indexOf("@I@") >= 0) {
				let strs = new Array();
				strs = str.split("@I@");
				localStorage.setItem('idcardInformation',strs[1]);
				return strs[1];
			}

			//人脸比对结果
			if (str.indexOf("@CompareFace@") >= 0) {
				let strs = new Array();
				strs = str.split("@CompareFace@");
				alert(strs[1]);

				return;
			}

			//条码识别结果
			if (str.indexOf("@ReadBarcode@") >= 0) {
				let strs = new Array();
				strs = str.split("@ReadBarcode@");
				alert(strs[1]);

				return;
			}

			//图片转base64结果
			if (str.indexOf("@Img2Base64@") >= 0) {
				let strs = new Array();
				strs = str.split("@Img2Base64@");
				alert(strs[1]);

				return;
			}

			//通过pid和vid获取设备索引的返回值
			if (str.indexOf("@GetCamIndexByPID@") >= 0) {
				let strs = new Array();
				strs = str.split("@GetCamIndexByPID@");
				alert(strs[1]);

				return;
			}

			//社保
			if (str.indexOf("@ICHANGEPIN@") >= 0) {
				let strs = new Array();
				strs = str.split("@ICHANGEPIN@");
				alert(strs[1]);

				return;
			}

			//社保
			if (str.indexOf("@IVERIFYPIN@") >= 0) {
				let strs = new Array();
				strs = str.split("@IVERIFYPIN@");
				alert(strs[1]);

				return;
			}

			//社保
			if (str.indexOf("@IREADSIEF06@") >= 0) {
				let strs = new Array();
				strs = str.split("@IREADSIEF06@");
				alert(strs[1]);

				return;
			}

			//社保
			if (str.indexOf("@IREADSIEF05@") >= 0) {
				let strs = new Array();
				strs = str.split("@IREADSIEF05@");
				alert(strs[1]);

				return;
			}

			//上传返回值
			if (str.indexOf("@Cap2HttpServer@") >= 0) {
				let strs = new Array();
				strs = str.split("@Cap2HttpServer@");
				alert("上传返回值:" + strs[1]);

				return;
			}

			//拍照为Base64返回值
			if (str.indexOf("@Cap2Base64@") >= 0) {
				let strs = new Array();
				strs = str.split("@Cap2Base64@");
				alert("拍照为Base64返回值:" + strs[1]);

				return;
			}

			if (str.indexOf(g_splitFlag) >= 0) {
				//视频帧
				let strs = new Array();
				strs = str.split(g_splitFlag);
				setImageWithBase64(strs[1]);
			}
		}

	};

	ws.onerror = function (error) {
		//如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
		console.log(error);
	}


	ws.onclose = function () {
		g_bConnectWS = false;

		// alert("连接已断开或服务端未开启");
	};

}
console.log(g_bConnectWS)

function sendWsMessage(jsonObj) {
	var jsonStr = JSON.stringify(jsonObj);
	console.log('发送连接',jsonStr)
	ws.send(jsonStr);
}



function load() {
	//连接服务
	StartWebSocket();

}


function unload() {
	alert("断开连接");
	if (g_bClosed) {
		return;
	}
	clearInterval();
	//反初始化
	let jsonObj = { FuncName: 'HWUnInitCamLib' };
	sendWsMessage(jsonObj);
}
//初始化
function sendInitMsg() {

	let jsonObj = { FuncName: 'HWInitCamLib' };
	sendWsMessage(jsonObj);
}


//显示视频帧
function setImageWithBase64(str) {
	var myimg = document.getElementById("myCanvas");
	if(str){
		myimg.src = "data:image/png;base64," + str;
	}else{
		return false
	}
	
}



//拍照
function Capture() {

	var filepath = document.getElementById("saveText").value;
	let jsonObj = { FuncName: 'camCaptureImageFile', argument: { filePath: filepath } };
	sendWsMessage(jsonObj);

}


function addoptionDev(s) {
	var obj = document.getElementById("curDev");
	var opt = new Option(s, obj.length);
	obj.options.add(opt);
}

function initDev(str) {
	//设备名称
	var strs = new Array();
	strs = str.split("@|@");

	for (let i = 0; i < strs.length; i++) {
		if (strs[i].length > 0) {
			addoptionDev(strs[i]);
		}
	}
	document.getElementById("curDev").value = 0;
}

function changedev(index) {
	if (index != g_index) {
		stop_preview();
		g_index = index;
		start_preview();
	}
}

function start_preview() {
	let jsonObj = { FuncName: 'StartPreview', index: g_index };
	sendWsMessage(jsonObj);
}

function stop_preview() {
	let jsonObj = { FuncName: 'StopPreview', index: g_index };
	sendWsMessage(jsonObj);
}

var num = 1;
function FormatNum(Source) {
	var strTemp = "";
	var len = 5;
	if (Source < 10) {
		len = 4;
	}
	else if (Source < 100) {
		len = 3;
	}
	else if (Source < 1000) {
		len = 2;
	}
	for (i = 0; i < len; i++) {
		strTemp += "0";
	}
	return strTemp + Source;
}


function capture() {
	var val = num;
	var filename = FormatNum(val);
	var ext = "jpg";
	var path = "D:\\Img" + filename + "." + ext;             //get_name()
	num++;
	let jsonObj = { FuncName: 'DoCapture', index: g_index, filepath: path };
	sendWsMessage(jsonObj);
}

//纠偏裁边
function RotateCrop(checked) {
	console.log(checked)
	if (checked) {
		let jsonObj = { FuncName: 'SetAutoCrop', index: g_index, flag: 1 };
		sendWsMessage(jsonObj);
	}
	else {
		let jsonObj = { FuncName: 'SetAutoCrop', index: g_index, flag: 0 };
		sendWsMessage(jsonObj);
	}
}

function CleanBlack(obj) {
	if (obj.checked) {
		let jsonObj = { FuncName: 'CleanBorder', index: g_index, flag: 1 };
		sendWsMessage(jsonObj);
	}
	else {
		let jsonObj = { FuncName: 'CleanBorder', index: g_index, flag: -1 };
		sendWsMessage(jsonObj);
	}
}

function readidcard() {
	let jsonObj = { FuncName: 'ReadIDCard' };
	return sendWsMessage(jsonObj);
}

function setJpgQuality(idx, val) {
	let jsonObj = { FuncName: 'SetJPGQuality', index: idx, flag: val };
	sendWsMessage(jsonObj);
}

function cap2HttpServer(idx, url) {
	let jsonObj = { FuncName: 'Cap2HttpServer', index: idx, flag: '"' + url + '"' };
	sendWsMessage(jsonObj);
}

function compareFace() {
	let jsonObj = { FuncName: 'CompareFace', index: g_index };
	sendWsMessage(jsonObj);
}

function readBarcode() {
	let jsonObj = { FuncName: 'ReadBarcode', index: g_index };
	sendWsMessage(jsonObj);
}

function image2Base64(str) {
	let jsonObj = { FuncName: 'Image2Base64', index: str };
	sendWsMessage(jsonObj);
}

function getcamidx(str) {
	let jsonObj = { FuncName: 'GetCamIndexByPID', index: str };
	sendWsMessage(jsonObj);
}

function getiReadSIEF05() {
	let jsonObj = { FuncName: 'IREADSIEF05' };
	sendWsMessage(jsonObj);
}

function getiReadSIEF06() {
	let jsonObj = { FuncName: 'IREADSIEF06' };
	sendWsMessage(jsonObj);
}

function getiVerifyPin() {
	let jsonObj = { FuncName: 'IVERIFYPIN' };
	sendWsMessage(jsonObj);
}

function getiChangePIN() {
	let jsonObj = { FuncName: 'ICHANGEPIN' };
	sendWsMessage(jsonObj);
}

function setVideoColor(idx, val) {
	let jsonObj = { FuncName: 'VideoColor', index: idx, flag: val };
	sendWsMessage(jsonObj);
}

function cap2base64(idx) {
	let jsonObj = { FuncName: 'Cap2Base64', index: idx };
	sendWsMessage(jsonObj);
}

//放大
function zoomin() {
	var obj1 = document.getElementById("curDev");
	var idx = obj1.selectedIndex;
	let jsonObj = { FuncName: 'VideoZoom', index: idx, flag: 1 };
	sendWsMessage(jsonObj);
}

//缩小
function zoomout() {
	var obj1 = document.getElementById("curDev");
	var idx = obj1.selectedIndex;
	let jsonObj = { FuncName: 'VideoZoom', index: idx, flag: -1 };
	sendWsMessage(jsonObj);
}

//左转
function rotleft() {
	var obj1 = document.getElementById("curDev");
	var idx = obj1.selectedIndex;
	let jsonObj = { FuncName: 'VideoRotate', index: idx, flag: 1 };
	sendWsMessage(jsonObj);
}


//左转
function rotright() {
	var obj1 = document.getElementById("curDev");
	var idx = obj1.selectedIndex;
	let jsonObj = { FuncName: 'VideoRotate', index: idx, flag: 2 };
	sendWsMessage(jsonObj);
}

//生成pdf
function createpdf(pdfPath, imgPath) {
	let jsonObj = { FuncName: 'CreatePDF', pdfpath: pdfPath, imgpath: imgPath };
	sendWsMessage(jsonObj);
}




export default {
	load, unload, Capture, changedev, capture, RotateCrop, CleanBlack, readidcard, setJpgQuality, cap2HttpServer, compareFace, readBarcode, image2Base64, getcamidx, getiReadSIEF05, getiReadSIEF06, getiVerifyPin, getiChangePIN, setVideoColor, cap2base64, zoomin, zoomout, rotleft, rotright, createpdf, start_preview , stop_preview,g_index
}