/**
 * 对象深拷贝
 * @param obj 原对象
 * @return 新对象
 */
 function deepClone(obj) {
    let objClone = Array.isArray(obj) ? [] : {}
    if (obj && typeof obj === 'object') {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          //判断ojb子元素是否为对象，如果是，递归复制
          if (obj[key] && typeof obj[key] === 'object') {
            objClone[key] = deepClone(obj[key])
          } else {
            //如果不是，简单复制
            objClone[key] = obj[key]
          }
        }
      }
    }
    return objClone
  }
  
  /**
   * 列表数据转换成树状结构数据
   * @param listData 列表数据
   * @param idProp 编号键名称
   * @param parentProp 父编号键名称
   * @param childProp 子级列表键名称
   * @return 树状结构数据
   */
  function toTree(originData, idProp, parentProp, childProp) {
    let listData = deepClone(originData)
    let result = []
    let temp = {}
    let len = listData.length
    for (let i = 0; i < len; i++) {
      // 以idProp作为索引存储元素，可以无需遍历直接定位元素
      temp[listData[i][idProp]] = listData[i]
    }
    for (let j = 0; j < len; j++) {
      let currentElement = listData[j]
      // 临时变量里面的当前元素的上级编号
      let tempCurrentElementParent = temp[currentElement[parentProp]]
      // 如果存在父元素
      // console.log(`tempCurrentElementParent====>`, tempCurrentElementParent);
      if (tempCurrentElementParent) {
        // 如果父元素没有childProp键，给父元素设置childProp键
        if (!tempCurrentElementParent[childProp]) {
          tempCurrentElementParent[childProp] = []
        }
        tempCurrentElementParent[childProp].push(currentElement)
      } else {
        result.push(currentElement)
      }
    }
    return result
  }
  
  // 不做深克隆
  function toTree2(originData, idProp, parentProp, childProp) {
    let listData = originData
    let result = []
    let temp = {}
    let len = listData.length
    for (let i = 0; i < len; i++) {
      // 以idProp作为索引存储元素，可以无需遍历直接定位元素
      temp[listData[i][idProp]] = listData[i]
    }
    for (let j = 0; j < len; j++) {
      let currentElement = listData[j]
      // 临时变量里面的当前元素的上级编号
      let tempCurrentElementParent = temp[currentElement[parentProp]]
      // 如果存在父元素
      // console.log(`tempCurrentElementParent====>`, tempCurrentElementParent);
      if (tempCurrentElementParent) {
        // 如果父元素没有childProp键，给父元素设置childProp键
        if (!tempCurrentElementParent[childProp]) {
          tempCurrentElementParent[childProp] = []
        }
        tempCurrentElementParent[childProp].push(currentElement)
      } else {
        result.push(currentElement)
      }
    }
    return { treeData: result, originData }
  }
  
  /**
   * 通过级联的最后一级id，获取所有层级的选中的id数组
   * @param  {any} content
   * @param  {any} id
   * @return
   */
  const getIntactIds = (content, id, result = [id]) => {
    if (id === '') return result
    for (let i = 0; i < content.length; i++) {
      let item = content[i]
      if (item.id === id) {
        item.parentId && result.unshift(item.parentId)
        getIntactIds(content, item.parentId, result)
      }
    }
    return result
  }

  /**
 * 通过级联的最后一级id，获取所有层级的选中的id数组
 * @param  {any} content 原始的扁平数组
 * @param  {any} id
 * @return
 */
const _GetIntactIds = (content, id, result = [id]) => {
    if (id === '') return result
    for (let i = 0; i < content.length; i++) {
        let item = content[i]
        if (item.id === id) {
            item.parentId && result.unshift(item.parentId)
            _GetIntactIds(content, item.parentId, result)
        }
    }
    return result
}
  


/**
 * 转换规则模板
 * @param template 模板字符串
 * @return 数组
 */
 function convertRuleTemplate(template) {
	let regx = /\{([^{}]*)}/
	let strRule = template
	let arrRule = []
	let index = 0
	while (strRule) {
		let matchResult = strRule.match(regx)
		if (matchResult) {
			if (matchResult.index) {
				arrRule.push({
					type: 'string',
					value: strRule.substr(0, matchResult.index)
				})
			}
			arrRule.push({
				type: matchResult[1].trim(),
				index: index
			})
			index++
			let indicator = matchResult[0].length + matchResult.index
			// 字符串遍历完毕
			if (indicator == strRule) {
				strRule = ''
			} else {
				strRule = strRule.substr(indicator)
			}
		} else {
			arrRule.push({
				type: 'string',
				value: strRule
			})
			strRule = ''
		}
	}
	return arrRule
}




  export default {
    toTree,
    toTree2,
    getIntactIds,
    deepClone,
    _GetIntactIds,
    convertRuleTemplate
  }
  